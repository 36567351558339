<template>
  <AppView>
    <!-- content @s -->
    <div class="nk-content nk-content-fluid">
      <div class="container-xl wide-xl">
        <div class="nk-content-inner">
          <div class="nk-content-body">
            <div class="nk-block-head nk-block-head-sm">
              <div class="nk-block-between g-3">
                <div class="nk-block-head-content">
                  <h3 class="nk-block-title page-title">Usuarios / <strong class="text-primary small">{{user_edit.name}} {{user_edit.lastname}}</strong></h3>
                </div>
                <div class="nk-block-head-content">
                  <router-link to="/sistema/usuarios" replace class="btn btn-outline-light bg-white d-none d-sm-inline-flex"><em class="icon ni ni-arrow-left"></em><span>Regresar</span></router-link>
                  <router-link to="/sistema/usuarios" replace class="btn btn-icon btn-outline-light bg-white d-inline-flex d-sm-none"><em class="icon ni ni-arrow-left"></em></router-link>
                </div>
              </div>
            </div><!-- .nk-block-head -->
            <div class="nk-block">
              <div class="card card-bordered">
                <div class="card-aside-wrap">
                  <div class="card-content">
                    <ul class="nav nav-tabs nav-tabs-mb-icon nav-tabs-card">
                      <li class="nav-item">
                        <a class="nav-link active" href="#"><em class="icon ni ni-user-circle"></em><span>Cuenta</span></a>
                      </li>
                    </ul><!-- .nav-tabs -->
                    <form @submit.prevent="save">
                      <div class="card-inner">
                        <div class="nk-block">
                          <div class="nk-block-head">
                            <h5 class="title">Configuración de la cuenta</h5>
                            <p>Datos básicos como nombre, correo electrónico, etc.</p>
                          </div><!-- .nk-block-head -->
                          <div class="profile-ud-list">
                            <div class="profile-ud-item">
                              <div class="profile-ud wider">
                                <span class="profile-ud-label">Nombre <span class="ml-1 text-danger">*</span></span>
                                <span class="profile-ud-value">
                                    <input type="text" class="form-control" autocomplete="nope" v-model="user_edit.name" required>
                                </span>
                              </div>
                            </div>
                            <div class="profile-ud-item">
                              <div class="profile-ud wider">
                                <span class="profile-ud-label">Apellidos <span class="ml-1 text-danger">*</span></span>
                                <span class="profile-ud-value">
                                    <input type="text" class="form-control" autocomplete="nope" v-model="user_edit.lastname" required>
                                </span>
                              </div>
                            </div>
                            <div class="profile-ud-item">
                              <div class="profile-ud wider">
                                <span class="profile-ud-label">Usuario <span class="ml-1 text-danger">*</span></span>
                                <span class="profile-ud-value">
                                    <input type="text" class="form-control" autocomplete="nope" v-model="user_edit.username" required>
                                </span>
                              </div>
                            </div>
                            <div class="profile-ud-item">
                              <div class="profile-ud wider">
                                <span class="profile-ud-label">Correo <span class="ml-1 text-danger">*</span></span>
                                <span class="profile-ud-value">
                                    <input type="email" class="form-control" autocomplete="nope" v-model="user_edit.email" required>
                                </span>
                              </div>
                            </div>
                            <div class="profile-ud-item">
                              <div class="profile-ud wider">
                                <span class="profile-ud-label">Contraseña</span>
                                <span class="profile-ud-value">
                                    <input type="password" class="form-control" autocomplete="new-password" v-model="password">
                                    <em class="text-soft fs-12px text-left w-100 d-inline-block">Si no deseas cambiar la contraseña, deja este campo vacío</em>
                                </span>
                              </div>
                            </div>
                            <div class="profile-ud-item">
                              <div class="profile-ud wider">
                                <span class="profile-ud-label">Repetir Contraseña</span>
                                <span class="profile-ud-value">
                                    <input type="password" class="form-control" autocomplete="new-password" v-model="password_confirm">
                                    <em v-if="!valid" class="text-danger fs-12px text-left w-100 d-inline-block">Las contraseñas no son iguales</em>
                                </span>
                              </div>
                            </div>
                            <div class="profile-ud-item">
                              <div class="profile-ud wider">
                                <span class="profile-ud-label">Rol <span class="ml-1 text-danger">*</span></span>
                                <span class="profile-ud-value">
                                  <select class="form-control" v-model="user_edit.role">
                                    <option v-for="rol in roles" :key="rol.role" :value="rol.role">{{rol.label}}</option>
                                  </select>
                                </span>
                              </div>
                            </div>
                            <div class="profile-ud-item" v-if="user_edit.role === 'cliente'">
                              <div class="profile-ud wider">
                                <span class="profile-ud-label">Servicios <span class="ml-1 text-danger">*</span></span>
                                <span class="profile-ud-value">
                                  <Multiselect
                                    v-model="user_edit.servicios"
                                    mode="tags"
                                    :close-on-select="false"
                                    :searchable="true"
                                    :create-option="true"
                                    :options="servicios"/>
                                </span>
                              </div>
                            </div>
                            <div class="profile-ud-item" v-if="user_edit.role === 'cliente'">
                              <div class="profile-ud wider">
                                <span class="profile-ud-label">Secciones <span class="ml-1 text-danger">*</span></span>
                                <span class="profile-ud-value">
                                  <Multiselect
                                    v-model="user_edit.secciones"
                                    mode="tags"
                                    :close-on-select="false"
                                    :searchable="true"
                                    :create-option="true"
                                    :options="secciones"/>
                                </span>
                              </div>
                            </div>
                            <div class="profile-ud-item" v-if="user_edit.role === 'supervisor'">
                              <div class="profile-ud wider">
                                <span class="profile-ud-label">Coordinador <span class="ml-1 text-danger">*</span></span>
                                <span class="profile-ud-value">
                                  <select class="form-control" v-model="user_edit.id_coordinador" required>
                                    <option disabled value="">Seleccionar coordinador</option>
                                    <option v-for="user in coordinadores" :key="user.id" :value="user.id">{{user.name}} {{user.lastname}}</option>
                                  </select>
                                </span>
                              </div>
                            </div>
                          </div><!-- .profile-ud-list -->
                          <div class="w-100 text-right">
                            <button type="submit" class="mt-2 btn btn-lg btn-primary" :disabled="!valid">Actualizar Usuario</button>
                          </div>
                        </div><!-- .nk-block -->
                        <div class="nk-block">
                          <div class="nk-block-head nk-block-head-line">
                            <h6 class="title overline-title text-base">Información Adicional</h6>
                          </div><!-- .nk-block-head -->
                          <div class="profile-ud-list">
                            <div class="profile-ud-item">
                              <div class="profile-ud wider">
                                <span class="profile-ud-label">ID</span>
                                <span class="profile-ud-value">{{user_edit.id_user}}</span>
                              </div>
                            </div>
                            <div class="profile-ud-item">
                              <div class="profile-ud wider">
                                <span class="profile-ud-label">Fecha de Alta</span>
                                <span class="profile-ud-value">{{user_edit.registered}}</span>
                              </div>
                            </div>
                            <div class="profile-ud-item">
                              <div class="profile-ud wider">
                                <span class="profile-ud-label">Último acceso</span>
                                <span class="profile-ud-value">{{user_edit.last_login}}</span>
                              </div>
                            </div>
                          </div><!-- .profile-ud-list -->
                        </div><!-- .nk-block -->
                      </div><!-- .card-inner -->
                    </form>
                  </div><!-- .card-content -->
                </div><!-- .card-aside-wrap -->
              </div><!-- .card -->
            </div><!-- .nk-block -->
          </div>
        </div>
      </div>
    </div>
    <!-- content @e -->
  </AppView>
</template>

<script>
import AppView from "@/components/sistema/AppView";
import {useApi} from "@/use/useApi";
import useAuth from "@/providers/auth";
import {ref, computed, onMounted, defineComponent, watchEffect} from 'vue';
import { useRouter, useRoute } from 'vue-router';
import Swal from "sweetalert2";
import {USER_ROLES} from "@/providers/config";
import Multiselect from '@vueform/multiselect';

export default defineComponent({
  name: "Editar Usuario",
  components: {AppView, Multiselect},
  setup() {
    //Data
    const { loading, make } = useApi();
    const router = useRouter();
    const {params} = useRoute();
    const {user} = useAuth();

    const roles = computed(() => {
      switch (user.value.role){
        case 'administrador': case 'superadmin':
          return USER_ROLES;
        default:
          return [];
      }
    });

    const user_edit = ref({
      id_user: '',
      name: '',
      lastname: '',
      username: '',
      email: '',
      registered: '',
      last_login: '',
      id_coordinador: '',
      servicios: [],
      secciones: []
    });
    
    const password = ref('');
    const password_confirm = ref('');
    const coordinadores = ref([]);
    const servicios = ref([]);
    const secciones = ref([]);

    //Computed
    const valid = ref(false);

    watchEffect(() =>{
      if(password.value.length > 0){
        if(password.value !== password_confirm.value){
          user_edit.value.password = null;
          valid.value = false;
        } else {
          user_edit.value.password = password.value;
          valid.value = true;
        }
      } else {
        valid.value = true;
      }
    });
    
    //Methods
    async function get(){
      loading.message = 'Obteniendo información';
      let {data} = await make('administrators/get', {id_user: params.id_user});
      if(data === null){
        router.push('/sistema/usuarios');
        Swal.fire('Error', 'Ocurrió un error al conectarse con el servidor', 'error');
      } else {
        if(data.error){
          router.push('/sistema/usuarios');
          Swal.fire('Error', data.message, 'error');
        } else {
          user_edit.value = data.user;
        }
      }
    }

    async function save(){
      if (user_edit.value.role === 'cliente' && user_edit.value.servicios.length == 0) {
        Swal.fire('Advertencia', 'Debes de seleccionar al menos un servicio.', 'info');

        return false;
      }

      if (user_edit.value.role === 'cliente' && user_edit.value.secciones.length == 0) {
        Swal.fire('Advertencia', 'Debes de seleccionar al menos una sección.', 'info');

        return false;
      }

      loading.message = 'Actualizando usuario';
      let {data} = await make('administrators/edit', {user: user_edit.value});
      if(data === null){
        Swal.fire('Error', 'Ocurrió un error al conectarse con el servidor', 'error');
      } else {
        if(data.error){
          Swal.fire('Error', data.message, 'error');
        } else {
          Swal.fire('Éxito', data.message, 'success').then(() => {
            if(user_edit.value.id_user === user.value.id_user){
              user.value = data.user;
            }
            router.push('/sistema/usuarios');
          });
        }
      }
    }

    async function getServicios(){
      loading.message = 'Obteniendo información';
      let {data} = await make('generales/get-serviciosmulti', {});
      if(data === null){
        Swal.fire('Error', 'Ocurrió un error al conectarse con el servidor', 'error');
      } else {
        if(data.error){
          Swal.fire('Error', data.message, 'error');
        } else {
          servicios.value = data.servicios;
        }
      }
    }

    async function getSecciones(){
      loading.message = 'Obteniendo información';
      let {data} = await make('generales/get-secciones', {});
      if(data === null){
        Swal.fire('Error', 'Ocurrió un error al conectarse con el servidor', 'error');
      } else {
        if(data.error){
          Swal.fire('Error', data.message, 'error');
        } else {
          secciones.value = data.secciones;
        }
      }
    }

    async function getCoordinadores(){
      loading.message = 'Obteniendo información';
      let {data} = await make('generales/get-coordinadores', {});
      if(data === null){
        Swal.fire('Error', 'Ocurrió un error al conectarse con el servidor', 'error');
      } else {
        if(data.error){
          Swal.fire('Error', data.message, 'error');
        } else {
          coordinadores.value = data.coordinadores;
        }
      }
    }

    getServicios();
    getSecciones();
    getCoordinadores();

    //Mounted
    onMounted(() => {
      get();
    });

    return {roles, user_edit, password, password_confirm, loading, valid, servicios, secciones, coordinadores, save};
  }
});
</script>

<style scoped>
.profile-ud-list {
  max-width: inherit;
}
.profile-ud-label {
  width: 140px;
  align-items: start;
  margin-top: 0.5em;
}
.profile-ud-value {
  line-height: 2.5em;
}
input, textarea, select {
  text-transform: initial;
}
</style>

<style src="@vueform/multiselect/themes/default.css"></style>
